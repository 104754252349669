<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create New Account"
                    : "Update Account"
                }}
              </strong>
              <div v-if="$route.name != route.form" class="card-header-actions">
                <b-button size="sm"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Close Account"
                          @click="handleDelete">
                  Close Account
                </b-button>
              </div>
            </div>
            <b-row>
              <BuildingDropdown :server-params="form"
                                :on-select="getMeters"
                                :form-group="true"/>
              <b-col sm="12">
                <form-group :validator="$v.form.accountId"
                            label="Account#"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter account#"
                                autocomplete="off"
                                v-model="form.accountId"
                                :disabled="$route.name != route.form"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.name"
                            label="Name"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter name"
                                class="text-uppercase"
                                autocomplete="off"
                                v-model="form.name"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group label="UEN" :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter UEN"
                                autocomplete="off"
                                v-model="form.uen"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.meters"
                            label="Meters"
                            :use-horizontal="false">
                  <treeselect v-model="form.meters"
                              placeholder="Select Meters"
                              :multiple="true"
                              :options="options.meter" />
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.address"
                            label="Billing Address"
                            :use-horizontal="false">
                  <b-textarea placeholder="Enter billing address"
                              class="text-uppercase"
                              v-model="form.address"></b-textarea>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.unit"
                            label="Billing Unit#"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter billing unit#"
                                class="text-uppercase"
                                autocomplete="off"
                                v-model="form.unit"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.postalCode"
                            label="Billing Postal Code"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter billing postal code"
                                autocomplete="off"
                                :maxlength="6"
                                v-model="form.postalCode"></b-form-input>
                </form-group>
              </b-col>
              <!--late payment due date offset (days)-->
              <b-col sm="12">
                <form-group :validator="$v.form.latePaymentDueDateOffset"
                            label="Late Payment Due Date (days)"
                            :use-horizontal="false">
                  <!--<b-form-input type="text"
                                placeholder="Enter late payment due date offset in days"
                                autocomplete="off"
                                :maxlength="6"
                                v-model="form.latePaymentDueDateOffset"></b-form-input>-->
                  <vue-numeric-input v-model="form.latePaymentDueDateOffset"
                                     :step="1"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                </form-group>
              </b-col>
              <!--auto compute late payment fee-->
              <b-col sm="12">
                <b-form-checkbox v-model="form.isAutoComputeLatePaymentFee"
                                 name="checkbox-1">
                  Auto compute late payment fee
                </b-form-checkbox>
              </b-col>
              <!--late fee percent (%)-->
              <b-col sm="12">
                <form-group :validator="$v.form.lateFeePercent"
                            label="Late Fee (%)"
                            :use-horizontal="false">
                  <vue-numeric-input v-model="form.lateFeePercent"
                                     :step="2"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                </form-group>
              </b-col>
              <!--minimum late fee (SGD)-->
              <b-col sm="12">
                <form-group :validator="$v.form.minimumLateFee"
                            label="Minimum Late Fee (SGD)"
                            :use-horizontal="false">
                  <vue-numeric-input v-model="form.minimumLateFee"
                                     :step="2"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                </form-group>
              </b-col>
              <!--late fee criteria-->
              <b-col sm="12">
                <form-group :validator="$v.form.lateFeeCondition"
                            label="Late Fee Conditions"
                            :use-horizontal="false">
                  <b-form-select v-model="form.lateFeeCondition" :options="lateFeeConditionOptions">
                  </b-form-select>
                </form-group>
              </b-col>
              <!--late payment fee calculation note-->
              <b-col sm="12">
                <b-alert show variant="warning">
                  <p class="font-sm">Note: Late payment fee calculation will be performed among: (which ever is high)</p>
                  <ul>
                    <li>Percent of outstanding</li>
                    <li>Fixed Flat Fee</li>
                    <li>Higher of percentage or fixed fee</li>
                  </ul>
                </b-alert>
              </b-col>
              <!--<b-col sm="12">
                <form-group :validator="$v.form.deposit"
                            label="Deposit (SGD)"
                            :use-horizontal="false">
                  <vue-numeric-input v-model="form.deposit"
                                     :step="2"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                </form-group>
              </b-col>-->
              <b-col sm="12">
                <form-group label="Deposit Details" :use-horizontal="false">
                  <b-textarea placeholder="Enter deposit details"
                              v-model="form.depositDetails">
                  </b-textarea>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group label="Email" :use-horizontal="false">
                  <b-form-tags placeholder="Enter email"
                               v-model="form.emails"
                               separator=";"></b-form-tags>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group label="Contact Details" :use-horizontal="false">
                  <b-textarea placeholder="Enter contact details"
                              v-model="form.contactDetails">
                  </b-textarea>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.autoSend"
                            label="Invoice Auto Send"
                            :use-horizontal="false">
                  <treeselect v-model="form.autoSend"
                              :options="options.autoSend"
                              placeholder="Select Invoice Auto Send"
                              :clearable="false" />
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm">Back</b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                {{ $route.name == route.form ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
      <b-col sm="6" v-if="$route.name != route.form">
        <b-form @submit.stop.prevent="handleChangePassword">
          <b-card>
            <div slot="header">
              <strong>Change Password</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group :validator="$v.formChangePassword.password"
                            label="Login Password"
                            :use-horizontal="false">
                  <b-form-input type="password"
                                placeholder="Enter login password"
                                autocomplete="off"
                                v-model="formChangePassword.password"></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                Save Changes
              </b-button>
            </div>
          </b-card>
        </b-form>
        <!--deposit history table-->
        <b-form @submit.stop.prevent="handleChangePassword">
          <b-card>
            <div slot="header">
              <strong>Deposit History | Total : ${{depositHistoryTotal}}(SGD)</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <b-table stripped hover :items="depoistHistory"></b-table>
                <!--table here-->
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    required,
    numeric,
    minLength,
    maxLength,
  } from "vuelidate/lib/validators";
  import { autoSend } from "@/shared/options";
  import BuildingDropdown from "@/components/common/BuildingDropdown";

  export default {
    data: () => ({
      route: {
        form: "AccountManagementCreate",
        table: "AccountManagement",
      },
      hideBuildingList: false,
      depositHistoryTotal: 0,
      depoistHistory: [],
      lateFeeConditionOptions: [
        {
          value: 1,
          text: "Late Fee %"
        },
        {
          value: 2,
          text: "Minimum Late Fee",
        },
        {
          value: 3,
          text: "Higher of Late Fee % or Minimum Late Fee $",
        },
      ],
      form: {
        name: null,
        uen: null,
        meters: [],
        address: null,
        unit: null,
        postalCode: null,
        accountId: null,
        buildingId: null,
        //deposit: null,
        depositDetails: null,
        emails: null,
        contactDetails: null,
        autoSend: false,
        latePaymentDueDateOffset: 0,
        isAutoComputeLatePaymentFee: false,
        lateFeePercent: 1,
        minimumLateFee: 50,
        lateFeeCondition: 1,
      },
      formChangePassword: {
        password: null,
      },
      options: {
        meter: [],
        autoSend,
      },
    }),
    validations: {
      form: {
        name: { required },
        meters: { required },
        address: { required },
        unit: { required },
        postalCode: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
        accountId: { required },
        autoSend: { required },
        lateFeeCondition: { required },
      },
      formChangePassword: {
        password: { required },
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      const self = this;
      self.getMeters();
      if (self.$route.name != self.route.form) {
        self.get();
        self.getDepositHistory();
      }
      else {
        self.getDefaultDayOffsetForLatePayment();
      }
    },
    methods: {
      getMeters() {
        const self = this;

        self.$store
          .dispatch("apis/get", {
            url: `/meter`,
            params: self.form,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.meter = response.data.data.map((x) => ({
                id: x.id,
                label: `${x.units.join(",")} (Meter Serial #${x.serialNo})`,
              }));
            }
          });
      },
      getDepositHistory() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: `/transaction/deposit-history?accountId=${self.$route.params.id}`,
          })
          .then(res => {
            if (res.error) {
              self.$message.error({
                zIndex: 10000,
                message: res.message,
              });
            } else {
              self.depoistHistory = res.data.history;
              self.depositHistoryTotal = res.data.depositTotal;
            }
          });
      },
      getDefaultDayOffsetForLatePayment() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: `/common/configuration?key=mcst.dueoffsetdays`,
          })
          .then(res => {
            if (res.error) {
              self.$message.error({
                zIndex: 10000,
                message: res.message,
              });
            } else {
              const offset = res.data.data.find(x => x.key === 'mcst.dueoffsetdays').value;
              self.form.latePaymentDueDateOffset = parseInt(offset);
            }
          });
      },
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/account/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                name: response.data.name,
                uen: response.data.uen,
                meters: response.data.meters.map((x) => x.id),
                address: response.data.address,
                unit: response.data.unit,
                postalCode: response.data.postalCode,
                accountId: response.data.accountId,
                deposit: response.data.deposit,
                depositDetails: response.data.depositDetails,
                buildingId: response.data.buildingId,
                emails: response.data.emails,
                contactDetails: response.data.contactDetails,
                autoSend: response.data.autoSend,
                latePaymentDueDateOffset: response.data.lateFeeDueDateOffset,
                isAutoComputeLatePaymentFee: response.data.autoComputeLateFee,
                lateFeePercent: response.data.lateFeePercent,
                minimumLateFee: response.data.minimumLateFee,
                lateFeeCondition: response.data.lateFeeCondition,
              };
            }
            loader.hide();
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this account. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/account";
        } else {
          _confirmText = "You are about to update this account. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/account/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      handleDelete() {
        const self = this;

        self.$dialog
          .confirm("You are about to close this account. Are you sure ?", {
            type: "hard",
            verification: self.$route.params.id,
            okText: "Yes, Close",
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: `/account/${self.$route.params.id}`,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      handleChangePassword() {
        const self = this;

        self.$v.formChangePassword.$touch();
        if (
          self.$v.formChangePassword.$pending ||
          self.$v.formChangePassword.$error
        )
          return;

        let _confirmText =
          "You are about to update login password. Are you sure ?",
          _okText = "Yes, Update",
          _action = "apis/put",
          _url = `/account/set-password/${self.$route.params.id}`;

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.formChangePassword,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });
                }
              });
          });
      },
    },
  };
</script>
